<template>
    <div class="head clear shaow">
        <div class="head_top clear">
            <div class="head_item cleft">
                <div class="headlogo">
                    <router-link to="/index">
                        <img src="/img/soz-logo.png" v-if="urlData==='sozdata'" alt="">
                        <img src="/img/epslogo.png" v-else alt="">
                    </router-link>
                </div>
            </div>
            <div class="head_group cright">
                <ul class="headlink cright">
                    <!-- <li v-show="!userObj.groupName"><router-link to="/login">登录</router-link></li>
                    <li v-show="userObj.groupName"><a href="javascript:;">欢迎您，<span style="color:#2a83d3">{{userObj.groupName}}</span></a></li> -->
                    <li><a href="http://chat10.live800.com/live800/chatClient/chatbox.jsp?companyID=448316&jid=6318557147&enterurl=http%3A%2F%2Fwww%2Eepsnet%2Ecom%2Ecn%2Fch%2FSys%2FReportPage%2Easpx%3FKey%3D230%26Type%3DKE%5FCLASS%5FEXPRESSREPORT&timestamp=1344843114447&pagereferrer=" target="_blank">在线咨询</a></li>
                    <li><a href="http://www.epschinadata.com/" target="_blank">English</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import {
  ajaxGet,
  setSessionItem,
  getSessionItem,
    urlSoz,
    getCookie
} from "../../until/common.js";
export default {
    name:'Header',
    data() {
        return{
            urlData:'',
            userObj:{},
        }
    },
    computed:{
        epsSid() {
            return getCookie('eps_sid')
        }
    },
    methods:{
        login(){
            ajaxGet("getApiLogin").then(res => {
                if(res&&res.data){
                    if(res.data.success){
                        setSessionItem('userData',res.data);
                        this.userObj = res.data
                    }else{
                        window.console.log(res.data.message)
                        return false;
                    }
                }
            }).catch(function(error) {
                // 请求失败处理
                window.console.log(error);
            });
        }
    },
    created() {
        if(window&&window.location.hostname===urlSoz){
            this.urlData = 'sozdata'
        }else{
            this.urlData = 'epsdata'
        }
        var that = this;
        that.login();
        if(getSessionItem('userData')){
            that.userObj = getSessionItem('userData')
        }
        if(this.epsSid) {
            this.$http.get('epsGetUserInfo',{sid:this.epsSid}).then(res=>{
                this.userObj = res.userInfoBo;
            });
        }
    },
}
</script>
<style lang="scss">
    
</style>