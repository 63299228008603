<template> 
    <div class="foot clear">
        <div class="foot_nav">
            <div class="inner">
                <dl class="tools">
                    <dt>工具服务</dt>                
                    <dd><a href="https://zhitu.sozdata.com/checkpaper.html" target="_blank" rel="noopener noreferrer">论文查重</a></dd>
                    <dd><a href="http://olap.epsnet.com.cn/transform.html?yunfenxi=1" target="_blank" rel="noopener noreferrer">云分析平台</a></dd>
                    <dd><router-link to="/Service" target="_blank">项目服务</router-link></dd>
                    <dd><router-link to="/Customiz" target="_blank">数据定制</router-link></dd>
                    <!-- <dd><router-link :to="{path:'/Product', query: {alipay:1}}" target="_blank">付费数据库</router-link></dd> -->
                </dl>
                <dl class="products">
                    <dt>旗下产品&网站</dt>                
                    <dd><a href="http://olap.epsnet.com.cn//transform.html" target="_blank" rel="noopener noreferrer">EPS数据平台</a></dd>
                    <dd><a href="http://yreb.sozdata.com/" target="_blank" rel="noopener noreferrer">长江经济带大数据平台</a></dd>
                    <dd><a href="http://microdata.sozdata.com/" target="_blank" rel="noopener noreferrer">中国微观经济数据查询系统</a></dd>
                    <dd><a href="http://crod.epsnet.com.cn/" target="_blank" rel="noopener noreferrer">中国革命老区大数据平台</a></dd>
                    <dd><a class="item" href="https://zhitu.sozdata.com/" target="_blank" rel="noopener noreferrer">知图平台</a></dd>
                    <dd><a href="https://www.shujugo.cn/" target="_blank" rel="noopener noreferrer">数据狗论坛</a></dd>
                    <dd><a href="http://kdd.epsnet.com.cn/" target="_blank" rel="noopener noreferrer">知识服务平台</a></dd>
                    <dd><a href="http://www.xinxundata.com/" target="_blank" rel="noopener noreferrer">新讯数据</a></dd>
                    <dd><a href="http://jimeng.sozdata.com/" target="_blank" rel="noopener noreferrer">搜知计蒙数据管理系统</a></dd>
                    <dd><a href="http://cnrrd.sozdata.com/" target="_blank" rel="noopener noreferrer">中国区域研究数据支撑平台</a></dd>
                </dl>
                <dl class="service">
                    <dt>客户咨询</dt>
                    <dd>客服热线：010-85786021-8001</dd>
                    <dd v-if="urlData==='sozdata'">客户服务：<a href="mailto:service@sozdata.com.cn">service@sozdata.com.cn</a></dd>
                    <dd v-else>客户服务：<a href="mailto:service@epsnet.com.cn">service@epsnet.com.cn</a></dd>
                    
                    <dd>数据咨询：<a href="mailto:data@epsnet.com.cn">data@epsnet.com.cn</a></dd>
                    <dd>项目服务：<a href="mailto:project@epsnet.com.cn">project@epsnet.com.cn</a></dd>
                </dl>
                <div class="split-line"></div>
                <!-- <div class="wx-public" v-if="this.urlData === 'epsdata'">
                    <div class="name">公众号</div>
                    <div class="ewm">
                        <img src="~@/assets/ewm.png" alt="">
                    </div>
                    <div class="tips">扫码关注EPS DATA公众号><br/>随时随地享受EPS DATA提供的内容与服务</div>
                </div> -->
                 <!-- v-if="this.urlData === 'sozdata'" -->
                <div class="wx-public-sz">
                    <div class="name">公众号</div>
                     <div class="ewm">
                        <div>
                            <img src="~@/assets/eps.png" alt="">
                            <div>
                                <span class="tips">EPS DATA</span>
                            </div>
                        </div>
                    </div>
                    <div class="ewm">
                        <div>
                            <img src="~@/assets/weChatCode.png" alt="">
                            <div>
                                <span class="tips">知图学术</span>
                            </div>
                        </div>
                    </div>
                   
                    <!-- <div class="tips">扫码关注EPS DATA公众号><br/>随时随地享受EPS DATA提供的内容与服务</div> -->
                </div>
            </div>
        </div>
        <div class="foot_btm clear">
            <div class="foot_box ">
                <div class="foot-logo cleft">
                    <router-link to="/index">
                    <img v-if="urlData==='sozdata'" src="~@/assets/sz-footer-logo-new.png" style="width:99px;height:29px" alt="">
                    <img v-else src="~@/assets/eps-footer-logo-new.png" style="width:99px;height:29px" alt="">
                    </router-link>
                </div>
                <div class="foot_item cleft">
                    <ul class="footlink">
                        <li><router-link to="/About" target="_blank">关于我们</router-link></li>
                        <li><router-link :to="{path:'/Product', query: {side:1}}" target="_blank">产品介绍</router-link></li>
                        <li><router-link to="/Service" target="_blank">项目服务</router-link></li>
                        <li><router-link to="/Customiz" target="_blank">数据定制</router-link></li>
                    </ul>
                </div>
                <div class="foot_group cright">
                    <ul class="footlink" v-if="urlData==='sozdata'">                      
                        <li><span>Copyright&copy;2019 北京搜知数据科技有限公司 版权所有</span></li>
                        <li><span>京ICP备17041952号-2 京公网安备11010802029194</span></li>                       
                    </ul>
                    <ul class="footlink" v-else-if="urlData==='dfinder'">                      
                        <li><span>Copyright&copy;2019 北京搜知数据科技有限公司 版权所有</span></li>
                        <li><span>京ICP备17041952号-1 京公网安备11010802025503</span></li>                       
                    </ul>
                    <ul class="footlink" v-else>
                        <li><span>Copyright&copy;2019 北京福卡斯特信息技术有限公司 版权所有</span></li>
                        <li><span>京ICP备09019565号-1 京公网安备11010802021028</span></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="fixed-service">
            <div class="item">
                <a href="http://chat10.live800.com/live800/chatClient/chatbox.jsp?companyID=448316&jid=6318557147&enterurl=http%3A%2F%2Fwww%2Eepsnet%2Ecom%2Ecn%2Fch%2FSys%2FReportPage%2Easpx%3FKey%3D230%26Type%3DKE%5FCLASS%5FEXPRESSREPORT&timestamp=1344843114447&pagereferrer=" target="_blank" rel="noopener noreferrer">
                    <span class="iconfont icon-kefu"></span>
                    <span class="text">客服</span>
                </a>
            </div>
            <Poptip trigger="hover" placement="left">
                <div class="item">
                    <a href="https://work.weixin.qq.com/kfid/kfcfead172bf1e5a41e" target="_blank" rel="noopener noreferrer">
                        <span class="iconfont icon-erweima"></span>
                        <span class="text">微信</span>
                    </a>
                </div>
                <div class="wxkfewm" slot="content">
                    <img src="~@/assets/qywx.png" style="width:150px;" alt="">
                    <div class="text">微信客服</div>
                </div>
            </Poptip >
        </div>

        <!-- 置顶-->
        <BackTop>
            <div class="back-top">
                <span class="iconfont icon-ai-top"></span>
            </div>
        </BackTop>
    </div>
</template>
<script>
import {getCookie, urlSoz,urlDfinder} from '../../until/common'
import config from '../../until/configs';
export default {
    name:'Footer',
    data() {
        return{
            urlData:'',
            epsUrl:config.baseUrl[1]
        }
    },
    computed:{
        eps_sid() {
            return getCookie('eps_sid');
        }
    },
    methods:{

    },
    mounted() {
        if(window&&window.location.hostname===urlSoz){
            this.urlData = 'sozdata'
        }else if(window&&window.location.hostname===urlDfinder){
            this.urlData = 'dfinder'
        }
        else {
            this.urlData = 'epsdata'
        }
    },
}
</script>
<style lang="scss">
    
</style>