<template>
    <div class="contenter" style="height:100%;padding-top:88px;padding-bottom:64px;">
        <Header style="margin-top:-88px;position: relative;"></Header>
        <div style="min-height:100%;width:100%;backgroup:#fdfdfd;">
            <div class="probanner">
                <div class="probannerInner service">
                    <div class="banner_img">
                        <div class="titleBan">
                            <span class="tit">数据服务</span> 
                            <span class="con">/DATA SERVICE</span>
                        </div>            
                        <div class="contentBan">致力于为用户提供简单、专业、高品质的数据解决方案</div>            
                    </div>
                </div>
            </div>
            <div class="service_Box">
                <div class="wrapperMar">
                    <div class="CommontTitle">
                        <div class="comTitleName titmart"><span class="comName">项目服务</span></div>
                    </div>
                    <div class="servicebox">
                        <p class="pea">EPS DATA专注于数据服务的发展与创新，致力于全方位满足用户的数据需求。同时坚持以国家和地区发展战略及重大前沿课题为导向，依托数据资源的全面性，时效性与权威性以及专业的技术能力，持续输出优质的项目解决方案，并为用户提供个性化的数据定制服务及高价值的决策支持服务。</p>                        
                        <div class="itemSeves cleft">
                            <div class="itemImg"><img src="/img/service1.png" alt=""></div>
                            <div class="itemTitle">资源整合</div>
                            <div class="itemPeap">提供全方位的数据采集、数据分类与数据整合，提升数据资源的全面性、时效性与利用效率。</div>
                        </div>
                        <div class="itemSeves cleft">
                            <div class="itemImg"><img src="/img/service2.png" alt=""></div>
                            <div class="itemTitle">商务智能</div>
                            <div class="itemPeap">将专题数据资源与商务智能平台相融合。实现一站式数据查询、提取、处理、分析、挖掘与可视化展现。</div>
                        </div>
                        <div class="itemSeves cleft">
                        <div class="itemImg"><img src="/img/service3.png" alt=""></div>
                            <div class="itemTitle">决策支持</div>
                            <div class="itemPeap">提供基于研究或应用的模型算法开发，便于用户利用动态数据直接获取经济或行业发展指数等输出结果。</div>
                        </div>
                        <div class="itemSeves cleft">
                            <div class="itemImg"><img src="/img/service4.png" alt=""></div>
                            <div class="itemTitle">数据定制</div>
                            <div class="itemPeap">满足数据需求方宏观经济数据、中观区域与产品发展数据及微观企业运行数据的个性化数据需求。</div>
                        </div>
                        <div class="itemSeves cleft">
                            <div class="itemImg"><img src="/img/service5.png" alt=""></div>
                            <div class="itemTitle">数据加工</div>
                            <div class="itemPeap">提供各类纸质资源的数字化加工服务，以及对各类数字化数据资源的采集、爬取、整合、清洗与规范化服务。</div>
                        </div>
                        <div class="itemSeves cleft">
                            <div class="itemImg"><img src="/img/service6.png" alt=""></div>
                            <div class="itemTitle">数据分析及可视化</div>
                            <div class="itemPeap">提供基于项目和课题研究的数据咨询服务，包含数据指标选取、模型算法优化设计、可视化图表开发等需求。</div>
                        </div>
                        <div class="imgTtit">成功案例</div>
                        <div class="clear"></div>
                        <div class="imgWhell" >
                            <div class="img_Box">
                                <div class="main_img clearfix" @mouseover="btnOpen" @mouseout="btnHide">
                                    <div class="minMain">
                                        <div class="item_imgs" v-for="(item,index) in list" :key="index">
                                            <img :src="item.imgUrl" width="488" height="275" />
                                        </div>
                                    </div>
                                </div>
                                <div class="btnMain" >
                                    <div class="left" @click="leftClick">
                                        <span class="gwicon imgLeftBtn"></span>
                                    </div>
                                    <div class="right" @click="rightClick">
                                        <span class="gwicon imgRightBtn"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from './commons/Header.vue';
import Footer from './commons/Footer.vue';
export default {
	name:'Service',
	components:{
        Header,
        Footer,
    },
    data() {
        return {
            list: [
                { imgUrl: '/img/success/001.png' },
                { imgUrl: '/img/success/002.png' },
                { imgUrl: '/img/success/003.jpg' },
                // { imgUrl: '/img/wg/wg1.png' },
                // { imgUrl: '/img/wg/wg2.png' },
                // { imgUrl: '/img/wg/wg3.png' },
                // { imgUrl: '/img/success/1.png' },
                // { imgUrl: '/img/success/2.png' },
            ],
            pressList: [
                { name: '1', isShow: false }, 
                { name: '2', isShow: false }, 
                { name: '3', isShow: false }, 
                // { name: '4', isShow: false }, 
                // { name: '5', isShow: false }
            ],
            numList: ['p0', 'p1', 'p2'],//, 'p3', 'p4'
            imgIndex: 0,
            imgTimer: null,
        }
    },
    computed: {
        
    },
    methods:{
        imgMove() {
            var Item = document.getElementsByClassName('item_imgs');
            this.imgTimer = setInterval(() => {
                this.numList.push(this.numList[0]);
                this.numList.shift();
                this.imgIndex++;
                for(var i = 0; i < Item.length; i++) {
                    Item[i].className = 'item_imgs ' + this.numList[i];
                }
                for(var j in this.pressList) {
                    this.pressList[j].isShow = false;
                }
                if(this.imgIndex > 2) {
                    this.imgIndex = 0;
                    this.pressList[this.imgIndex].isShow = true;
                } else {
                    this.pressList[this.imgIndex].isShow = true;
                }
            }, 4000)
        },
        btnOpen() {
            clearInterval(this.imgTimer);
        },
        btnHide() {
            this.imgMove();
        },
        leftClick() {
            var Item = document.getElementsByClassName('item_imgs');
            this.numList.unshift(this.numList[2]);
            this.numList.pop();
            for(var i = 0; i < Item.length; i++) {
                Item[i].className = 'item_imgs ' + this.numList[i];
            }
            for(var j in this.pressList) {
                this.pressList[j].isShow = false;
            }
            this.imgIndex--;
                if(this.imgIndex < 0) {
                    this.imgIndex = 2;
                    this.pressList[this.imgIndex].isShow = true;
                } else {
                    this.pressList[this.imgIndex].isShow = true;
                }
        },
        rightClick() {
            var Item = document.getElementsByClassName('item_imgs');
            this.numList.push(this.numList[0]);
            this.numList.shift();
            for(var i = 0; i < Item.length; i++) {
                Item[i].className = 'item_imgs ' + this.numList[i];
            }
            for(var j in this.pressList) {
                this.pressList[j].isShow = false;
            }
            this.imgIndex++;
            if(this.imgIndex > 2) {
                this.imgIndex = 0;
                this.pressList[this.imgIndex].isShow = true;
            } else {
                this.pressList[this.imgIndex].isShow = true;
            }
        }
    },
    mounted() {
        var Item = document.getElementsByClassName('item_imgs');
        for(var i = 0; i < Item.length; i++) {
            Item[i].className = 'item_imgs ' + this.numList[i];
        }
        this.imgMove();
        this.pressList[0].isShow = true;
    },
}
</script>
<style>
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.img_Box {
    position: relative;
    width: 956px;
    height: 430px;
    margin: 10px auto;
}

.main_img {
    width:760px;
    width:100%;
    height: 430px;
    position: absolute;
    top: 0px;
    left:0px;
    /*margin-left: -350px;*/
    overflow: hidden;
}

.item_imgs {
    list-style: none;
    width:488px;
    height:275px;
    position: absolute;
    left: 0px;
    top:110px;
    transition: all 0.3s ease;
}
.p0 {
    transform: translate3d(0px, 0, 0) scale(1);
    opacity: 0.8;
    z-index: 3;
}

.p1 {
    transform: translate3d(230px, 0, 0) scale(1.77);
    opacity: 1;
    z-index: 4;
}

.p2 {
   transform: translate3d(473px, 0, 0) scale(1);
    opacity: 0.8;
    z-index: 3;
}
/* .p0 {
    transform: translate3d(-380px, 0, 0) scale(1);
    opacity: 0.1;
    z-index: 1;
}

.p1 {
    transform: translate3d(-5px, 0, 0) scale(1);
    opacity: 0.8;
    z-index: 2;
}

.p2 {
    transform: translate3d(473px, 0, 0) scale(1);
    opacity: 0.8;
    z-index: 3;
}

.p3 {
    transform: translate3d(230px, 0, 0) scale(1.5);
    opacity: 1;
    z-index: 4;
}

.p4 {
    transform: translate3d(955px, 0, 0) scale(1);
    opacity: 0.1;
    z-index: 3;
} */

.newItem {
    position: absolute;
    left: 0px;
    top: 0px;
}

.changeItem {
    position: absolute;
    left: 50%;
    top: 0px;
    margin-left: -202px;
    transform: scale(1.4);
}

/* .item_imgs img {
    width: 100%;
    height: 100%;
} */

.btnMain {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.left {
    position: absolute;
    left: -55px;
    top: 50%;
    margin-top: -15px;
    z-index: 10;
}

.right {
    position: absolute;
    right: -55px;
    top: 50%;
    margin-top: -15px;
    z-index: 10;
}

.left img,
.right img {
    width: 30px;
    height: 30px;
}
.imgLeftBtn{
    cursor: pointer;
    height:40px;
    width:40px;
    background-position:0 -410px;
}
.imgLeftBtn:hover{
    height:40px;
    width:40px;
    background-position:0 -450px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.imgRightBtn {
    cursor: pointer;
    height:40px;
    width: 40px;
    background-position:0 -410px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.imgRightBtn:hover{
    height:40px;
    width:40px;
    background-position:0 -450px;
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}



</style>